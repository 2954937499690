import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import api from "@/services/api";
import url from "@/router/urls";

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from "@/store/actions/app.actions";
import { alertActions } from "@/store/actions/alert.actions";
import { reverse } from 'named-urls';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import Error403 from "@Pages/Error/Error403";
import Button from "@Elements/Button/Button";
import LinkButton from "@Elements/Button/LinkButton";
import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import Alert from "@Elements/Alert/Alert";
import Pagination from "@Components/Pagination/Pagination";
import CheckMark from '@Assets/icons/Check_circle.svg';
import Form from 'react-bootstrap/Form';
import TranscriptionsItemPage from '@Elements/Transcriptions/TranscriptionsItemPage';
import styles from "@Pages/EventPages/EventPages.module.sass";

const EventPages = () => {   
	const [haveAcess, setHaveAccess] = useState(false);
	const [event, setEvent] = useState('');
	const [teams, setTeams] = useState([]);
	const [activeTeam, setActiveTeam] = useState(null);
	const [pagesSignedTeam, setPagesSignedTeam] = useState({});
	const [transcriptions, setTranscriptions] = useState([]);
	const [activePages, setActivePages]= useState([]);
	const [selectedTranscription ,setSelectedTranscription] = useState(null);
	const [selectedTranscriptionPages, setSelectedTranscriptionPages] = useState([]);
	const [currentPage, setCurrentPage]= useState(1);
	const [onlyUnAssignedPages, setOnlyUnAssignedPages] = useState(false);
	const [numberPagesActiveTeam, setNumberPagesActiveTeam] = useState(0);
	const [clickedPage, setClickedPage] = useState([]);
	const [timeoutId, setTimeoutId] = useState(null);

	const { t } = useTranslation();
	const { id, teamId } = useParams();
	const dispatch = useDispatch();

	const userEmail = useSelector(state => state.auth.credentials.email);
	const securityGroup = useSelector((state) => state.auth.credentials.securityGroup);
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label: t('events.allEvents') , id: 2, link: url.events },
		{ label: event ? event.name : t('events.event'), id: 3, link: reverse(`${url.event}`, { id: id }) },
		{ label: t('events.transcriptions.projects'), id: 4 },
	];

	const getEvent = async () => {
		try {
			const res = await api.get(`/events/${id}`);

			if(res.status === 200) {
				setEvent(res.data);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text:  t('events.pages.warnings.event'),
				close: true,
			}));
		}
	};

	const getTeams = async () => {
		try {
			const res = await api.get(`/events/${id}/teams`);

			if(res.status === 200) {
				const teamsData = res.data.map(team => ({ id: team.id, name: team.name }));
				teamsData.push({name: '', id: 0});
				setTeams(teamsData);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.pages.warnings.teams'),
				close: true,
			}));
		}
	};

	const getPagesForTeam = async (team, teamName) => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events/${id}/teams/${team.id}/pages`);
			if (res.status === 200) {
				return {data: res.data, name: teamName, id: team.id};
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.pages.warnings.pagesForTeam'),
				close: true,
			}));
		}
	};

	const getPagesForTeams = async () => {
		const promises = teams.map(team => getPagesForTeam(team, team.name));

		try {
			const teamPages = (await Promise.all(promises));
			setPagesSignedTeam(teamPages);
			dispatch(appActions.setLoading(false));
		} catch (error) {
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.pages.warnings.pagesForTeams'),
				close: true,
			}));
		}
	};

	const addPagesToTeam = async (add, idsPage, teamIdToRemoveFrom) => {
		dispatch(appActions.setLoading(true));
		try {
			if(add) {
				const res = await api.put(`/events/${id}/teams/${activeTeam}/pages`, idsPage);

				if (res.status === 200) {
					getPagesForTeams();
					setNumberPagesActiveTeam(numberPagesActiveTeam + idsPage.length);
				}
			} else {
				const res = await api.delete(`/events/${id}/teams/${teamIdToRemoveFrom}/pages`, {data: idsPage});

				if (res.status === 200) {
					getPagesForTeams();
					if(teamIdToRemoveFrom === Number(activeTeam)) {
						setNumberPagesActiveTeam(numberPagesActiveTeam - 1);
					}
				}
			}
			
			dispatch(appActions.setLoading(false));
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text:  t('events.pages.warnings.addPages'),
				close: true,
			}));
		}
	};

	const getTranscriptions = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events/${id}/transcriptions?perpage=100`);
			if (res.status === 200) {
				setTranscriptions(res.data.transcriptions);
				if(res.data.transcriptions.length > 0) {
					setSelectedTranscription(res.data.transcriptions[0].id);
				}
				
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.pages.warnings.getTranscriptions'),
				close: true,
			}));
		}
	};

	useEffect(() => {
		if(id) {
			dispatch(appActions.setLoading(true));
			getEvent();
			getTeams();
			getTranscriptions();
		}
	}, [id]);

	useEffect(() => {
		const admin = CheckSecurityGroupPermission("canCreateEvents", securityGroup);

		if(admin) {
			setHaveAccess(admin);
		} else if (id && !admin && event && event.organizer?.email) {
			setHaveAccess(userEmail === event.organizer.email);
		} else {
			setHaveAccess(false);
		}
	}, [securityGroup, event, id, isLoggedIn]);

	const getPaginationPagesForTrs = async (transId) => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/transcriptions/${transId}?page=${currentPage}&perpage=24`);
			if (res.status === 200) {
				setSelectedTranscriptionPages(res.data.pages);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.pages.warnings.getTranscription'),
				close: true,
			}));
		}
	};

	const getOnlyUnAssignedPages = (checked) => {
		if(checked) {
			const filteredPages = activePages.filter(page => page.teamName === '');
			setActivePages(filteredPages);
			setOnlyUnAssignedPages(checked);
		} else {
			getPagesForSelectedTransciption();
			setOnlyUnAssignedPages(checked);
		}
	};

	const signAllPagesWithoutTeam = () => {
		const pagesToSend = activePages.filter(page => page.teamName === '').map(page => page.id);
		addPagesToTeam(true, pagesToSend);
	};

	useEffect(() => {
		if(selectedTranscription) {
			getPaginationPagesForTrs(selectedTranscription);
		}
		setOnlyUnAssignedPages(false);
	}, [currentPage, selectedTranscription]);

	useEffect(() => {
		if(teams.length > 0 && teamId) {
			const teamToLoad = teams.find(team => team.id === Number(teamId));
			setActiveTeam(teamToLoad.id);
		} else if(teams.length > 0) {
			const teamToLoad = teams.find(team => team.id != 0);
			setActiveTeam(teamToLoad.id);
		}
	}, [teams, teamId]);

	useEffect(() => {
		if(activeTeam && pagesSignedTeam.length > 0) {
			const pagesTeam = pagesSignedTeam.find(tds =>tds.id === Number(activeTeam)).data.reduce((counter, trs) => {
				return counter + trs.pages.length;
			}, 0);

			setNumberPagesActiveTeam(pagesTeam);
		}		
	}, [activeTeam, pagesSignedTeam]);

	const getPagesForSelectedTransciption = () => {
		if(!selectedTranscriptionPages || (selectedTranscriptionPages.list && selectedTranscriptionPages?.list?.length === 0)) {
			setActivePages([]);
			return; 
		}
		
		if(selectedTranscriptionPages && selectedTranscriptionPages?.list && selectedTranscriptionPages.list.length > 0) {
			const result = selectedTranscriptionPages.list.reduce((acc, page) => {
				const x = pagesSignedTeam.map(team => {
					return team.data.map(tr => {
						return tr.pages.map(p => {
							if(p.id === page.id) {
								return {...p, "teamName": team.name, "teamId": team.id};
							}
						});
					
					});
				});
				return [...acc, x];
			}, []);

			const filtered = result.flat(4).filter(r => r);
	
			setActivePages(filtered);
		}
	};

	useEffect(() => {
		getPagesForSelectedTransciption();
	}, [selectedTranscriptionPages, pagesSignedTeam]);

	useEffect(() => {
		if(teams && teams.length > 0) {
			getPagesForTeams();
		}
	}, [teams]);

	useEffect(() => {
		if (clickedPage.length > 0) {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			const newTimeoutId = setTimeout(() => {
				setClickedPage([]);
			}, 3000);

			setTimeoutId(newTimeoutId);
		}
	}, [clickedPage]);

	if (!haveAcess) return <Error403 />;

	return (
		<>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
			</Container>

			<Container>
				<Alert />
				<div className={styles["event-pages__back"]}>
					<LinkButton variant='text' href={reverse(`${url.event}`, { id: id, tab: "teams" })}>{t('events.pages.buttons.back')}</LinkButton>
				</div>
				<div className={styles["event-pages__title"]}>
					<p className={styles["event-pages__title"]}>{t('events.pages.title')} ({numberPagesActiveTeam})</p>
				</div>
				<div className={styles["event-pages__description"]}>
					<p className={styles["event-pages__subtitle"]}>{t('events.pages.subTitle')}</p>
					<div className={styles["event-pages__select"]}>
						<Form.Control as='select' onChange={(e) => {setActiveTeam(e.target.value);}} value={activeTeam ? activeTeam : ''}>
							{teams.map(team => team.name != '' && <option value={team.id} key={team.id}>{team.name}</option>)}
						</Form.Control>
					
					</div>
				</div>
				<div className={styles["event-pages__buttons"]}>
					{transcriptions.map(trn => {
						return(
							<Button className={[styles[`event-pages__button-transcription`], trn.id === selectedTranscription && styles[`event-pages__button-transcription--active`]]} 
								key={trn.id} onClick={() => {setSelectedTranscription(trn.id), setCurrentPage(1);}}
							>
								<span title={trn.titleLabel}>{trn.titleLabel}</span>
							</Button>);
					})}
				</div>
			</Container>
			<div className={styles["event-pages__pages"]}>
				<Container>
					<Pagination
						currentPage={selectedTranscriptionPages.page}
						pagesNum={selectedTranscriptionPages.pages}
						onGoToPage={setCurrentPage}
						leftTopChildren={
							<div className={styles["event-pages__buttons-assign-hide"]}>
								<Button className={styles["event-pages__buttons-assigne-all"]} variant='secondary' onClick={() => signAllPagesWithoutTeam()}>Przydziel wszystkie nieprzypisane strony</Button>
								<div className={styles["event-pages__hide"]}>
									<input id='hide-assigned' className={styles["event-pages__hide-checkbox"]} type='checkbox' checked={onlyUnAssignedPages}
										onChange={(e)=> getOnlyUnAssignedPages(e.target.checked)}
									/><label className={styles["event-pages__hide-label"]} htmlFor='hide-assigned'>{t('events.pages.hideSigned')}</label>
								</div>
							</div>
						}
					>
						<div className={styles["event-pages__row"]}>
							{activePages.map(page => {
								return (
									<div className={styles["event-pages__item"]} key={page.id}>
										<div className={styles["event-pages__mask"]}>
											<div className={styles["event-pages__add"]}>
												<input type='checkbox' value={page.id} checked={page.teamName ? true : false} onChange={(e) => {
													addPagesToTeam(e.target.checked, [page.id], page.teamId);
													e.target.checked && setClickedPage([...clickedPage, page.id]); 
												}}
												/><div>{page.teamName}</div>
											</div>
											{clickedPage && clickedPage.includes(page.id) && 
											<div className={styles[`event-pages__added-mask`]}>
												<div className={styles[`event-pages__added-mask-inner`]}>
													<div>
														<img src={CheckMark} alt='checkmark' />
													</div>
													<div className={styles[`event-pages__added-mask__text`]}>{t('events.pages.addedInfo')} <br /> <strong>{page.teamName}</strong></div>
												</div>
											</div>}
										</div>
										<TranscriptionsItemPage
											key={page.id}
											page={page}
											transcriptionId={page.id}
											percentOfVerified={page.percentOfTranscribed}
										/>
									</div>
								);

							})}
						</div>
					</Pagination>
				
				</Container>
			</div>
		</>
	);
};

EventPages.propTypes = {};

export default EventPages;