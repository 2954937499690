import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import api from "@/services/api";
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { alertActions } from "@/store/actions/alert.actions";
import { useTranslation } from 'react-i18next';

import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import styles from "@Pages/Event/Event.module.sass";

const ModalSendMessage = ({modalVisible, setModalVisible, eventId}) => {
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');
	
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const sendMessage = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.post(`/events/${eventId}/messages?title=${title}&content=${content}`);

			if (res.status === 200) {
				dispatch(appActions.setLoading(false));
				dispatch(
					alertActions.setAlert({
						type: "success",
						icon: "icon-circle-accept",
						text: t('events.teams.sendMessageUsers.close'),
						close: true,
					}),
				);
				setTitle('');
				setContent('');
			}
			window.scrollTo(0, 0);
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(
				alertActions.setAlert({
					type: "danger",
					icon: "icon-circle-warning-empty",
					text: t('events.teams.sendMessageUsers.error'),
					close: true,
				}),
			);
			window.scrollTo(0, 0);
		}
	};
    
	return (
		<ModalWrapperChildren
			modalVisible={modalVisible}
			setModalVisible={() => setModalVisible(false)}
			title={t('events.teams.sendMessageUsers.title')}
			closeButtonName={t('events.teams.sendMessageUsers.cancel')}
			confirmButtonName={t('events.teams.sendMessageUsers.close')}
			onConfirm={() => {sendMessage(), setModalVisible(false);}}
			withFooter
		>
			<div className={ styles["teams__send-email"] }>
				<div className={ styles["teams__send-email__title"] }>
					<label className={ styles["teams__send-email__label"] }>{t('events.teams.sendMessageUsers.titleLabel')}</label>		
					<input className={ styles["teams__send-email__input"] } onChange={e => setTitle(e.target.value)}/>
				</div>
				<div className={ styles["teams__send-email__content"] }>
					<label className={ styles["teams__send-email__label"] }>{t('events.teams.sendMessageUsers.contentLabel')}</label>
					<textarea className={ styles["teams__send-email__textarea"] } onChange={e => setContent(e.target.value)}/>
				</div>
			</div>
		</ModalWrapperChildren>
	);
};

ModalSendMessage.propTypes = {
	modalVisible: PropTypes.bool, 
	setModalVisible: PropTypes.func, 
	eventId: PropTypes.string,
};

export default ModalSendMessage;