import React, { useState, useEffect } from 'react';
import api from "@/services/api";
import url from "@/router/urls";

import { useLocation  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { appActions } from "@/store/actions/app.actions";
import { alertActions } from "@/store/actions/alert.actions";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { reverse } from 'named-urls';
import { addDays, format, isBefore } from 'date-fns';
import { Container } from 'react-bootstrap';
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import FormInput from "@Elements/Form/FormInput";
import Divider from "@Components/Divider/Divider";
import DatePickerComponent from "@Pages/EventForms/parts/DatePickerComponent";
import Button from "@Elements/Button/Button";
import LinkButton from "@Elements/Button/LinkButton";
import Alert from "@Elements/Alert/Alert";
import Error403 from "@Pages/Error/Error403";

import "react-datepicker/dist/react-datepicker.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from "@Pages/EventForms/EventCreateEdit.module.sass";

const EventCreateEdit = () => {
	const [haveAcess, setHaveAccess] = useState(false);
	const [descriptionState, setDescriptionState] = useState('');
	const [openingState, setOpeningState] = useState('');
	const [startDate, setStartDate] = useState(addDays(new Date(), 1));
	const [endDate, setEndDate] = useState(addDays(new Date(), 7));
	const [event, setEvent] = useState(null);
	const [fileName, setFileName] = useState(undefined);
	const [style, setStyle] = useState(undefined);
	const [isOpening, setIsOpening] = useState(false);

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const userEmail = useSelector(state => state.auth.credentials.email);
	const securityGroup = useSelector((state) => state.auth.credentials.securityGroup);
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	const { id } = useParams();
	const location = useLocation();
	
	const {
		register,
		handleSubmit,
		setValue,
		formState: {
			errors,
			isSubmitted,
		},
		clearErrors,
		setError,
	} = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			name: '',
			organizerEmail: userEmail,
			startDate: '',
			endDate: '',
			description: '',
			openingInfo: '',
			thumbnail: '',
			announced: false,
			openForEnrollment: false,
		},
	});

	const getEditorText = (html, onChangeFunc) => {
		if(html) {
			const raw = convertFromRaw(JSON.parse(html));
			const state = EditorState.createWithContent(raw);
			onChangeFunc(state);
		}
	};

	const onDescriptionStateChange = (e) => {
		const raw = convertToRaw(e.getCurrentContent());
		validDescription(raw);
		setDescriptionState(e);
		setValue('description', raw);
	};

	const onOpeningStateChange = (e) => {
		const raw = convertToRaw(e.getCurrentContent());
		setOpeningState(e);
		setValue('openingInfo', raw);
	};

	const getEvent = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events/${id}`);
			if(res.status === 200) {
				setEvent(res.data);
				if(JSON.parse(res.data.description) != "") {
					getEditorText(res.data.description, onDescriptionStateChange);
				}
				
				if(JSON.parse(res.data.openingInfo) != "") {
					getEditorText(res.data.openingInfo, onOpeningStateChange);
				}
			}
		} catch (error) {
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.create.warnings.getEvent'),
				close: true,
			}));
		} finally {
			dispatch(appActions.setLoading(false));
		}
	};
	
	const valdiateDate = () => {
		if(isBefore(endDate, startDate)) {
			setError('endDate', { type: 'custom', message: t('events.create.warnings.date') });
		} else {
			clearErrors('endDate');
		}
	};

	const validDescription = (content) => {
		const hasText = content?.blocks?.filter(block => block.text.trim() !== '').length > 0;
		if (isOpening && (!content || content === "")) {
			setError('description',  { type: 'custom', message: t('events.create.warnings.descriptionRequired') });
		} else if (isOpening && !hasText) {
			setError('description',  { type: 'custom', message: t('events.create.warnings.descriptionRequired') });
		} else {
			clearErrors('description');
		}
	};

	const validLogo = (logo) => {
		if(isOpening && !logo) {
			register('file', {required: t('events.create.warnings.logoRequired')});
		} else {
			clearErrors('file');
		}
	};

	const onChangeFile = (e) => {
		const file = e.target.files[0];

		if (file) {
			// Get the file extension
			const fileExtension = file.name.split('.').pop().toLowerCase();
			// Check if the file extension
			if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg') {
				// File is valid
				setFileName(file.name);
				const reader = new FileReader();
				reader.onloadend = () => {
					const dataURL = reader.result;
					setStyle(dataURL);
				};
				reader.readAsDataURL(file);
				clearErrors('file');

			} else {
				// File is not valid, reset the input
				setError('file', { type: 'custom', message: t('events.create.warnings.badFormat') });
				e.target.value = '';
				setFileName('');
				setStyle('');
			}
		}

	};

	const addFile = async (eventId, file) => {
		try {
			let formData = new FormData();
			formData.append("file", file);
			const resImage = await api.post(`/events/${eventId}/thumbnail`, formData,  { headers: { 'Content-Type': 'multipart/form-data' } } );
			return resImage;
			
		} catch (error) {
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.create.warnings.addLogo'),
				close: true,
			}));
		} finally {
			dispatch(appActions.setLoading(false));
		}
	};

	const addTeamA = async (eventId) => {
		try {
			const team = {
				name: "Drużyna A",
				memberEmails: [],
				transcriptionPages: {},
				characterCount: 0,
				wordCount: 0,
				verseCount: 0,
			};

			const res = await api.put(`/events/${eventId}/teams`, [team]);
			return res;
		} catch (error) {
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.create.warnings.addTeam'),
				close: true,
			}));
		}
	};

	const onSubmit = async (data) => {
		dispatch(appActions.setLoading(true));
		const description = JSON.stringify(data.description);
		const opening = JSON.stringify(data.openingInfo);
		const dataToSend = {
			"name": data.name,
			"description": description,
			"openingInfo": opening,
			"organizerEmail":data.organizerEmail,
			"announced": isOpening ? true : data.announced,
			"openForEnrollment":  isOpening ? true : data.openForEnrollment,
			"startDate": format(startDate, 'yyyy-MM-dd HH:mm'),
			"endDate":  format(endDate, 'yyyy-MM-dd HH:mm'),
		};	

		dispatch(appActions.setLoading(false));

		try {
			if(id) {
				const res = await api.put(`/events/${id}`, dataToSend);
				if(res.status === 200 && data.file[0]) {
					const resFile = await addFile(id,  data.file[0]);
					if(resFile.status === 204) {
						history.push(reverse(`${url.event}`, { id: res.data.id }) );
					}
				} else if (res.status === 200) {
					history.push(reverse(`${url.event}`, { id: res.data.id }) );
				}
	
			} else {			
				const res = await api.post(`/events/`, dataToSend);
				if(res.status === 200 && data.file[0]) {
					const resFile = await addFile(res.data.id,  data.file[0]);
					if(resFile.status === 204) {
						const resTeam = await addTeamA(res.data.id);
						if(resTeam.status === 200) {
							history.push(reverse(`${url.event}`, { id: res.data.id }) );
						}
					}
				} else if (res.status === 200) {
					const resTeam = await addTeamA(res.data.id);
					if(resTeam.status === 200) {
						history.push(reverse(`${url.event}`, { id: res.data.id }) );
					}
				}
			}
		} catch (error) {
			let errorFound = false;
			if (error.data?.announced) {
				if (error.data?.announced === "unAnnouncing_AnnouncedEvent") {
					dispatch(alertActions.setAlert({
						type: "danger",
						icon:  "icon-circle-warning-empty",
						text: t('events.create.warnings.unAnnouncing_AnnouncedEvent'),
						close: true,
					}));
					errorFound = true;
				} else if (error.data?.announced === "openingAlreadyStartedAndAnnouncedEvent") {
					dispatch(alertActions.setAlert({
						type: "danger",
						icon:  "icon-circle-warning-empty",
						text: t('events.create.warnings.openingAlreadyStartedAndAnnouncedEvent'),
						close: true,
					}));
					errorFound = true;
				} else if (error.data?.announced === "startEndMissingForAnnounced") {
					dispatch(alertActions.setAlert({
						type: "danger",
						icon:  "icon-circle-warning-empty",
						text: t('events.create.warnings.startEndMissingForAnnounced'),
						close: true,
					}));
					errorFound = true;
				} else if (error.data?.announced === "startInPastForAnnounced") {
					dispatch(alertActions.setAlert({
						type: "danger",
						icon:  "icon-circle-warning-empty",
						text: t('events.create.warnings.startInPastForAnnounced'),
						close: true,
					}));
					errorFound = true;
				}
			} else if (error.data?.name) {
				if (error.data?.name === "nameRequired") {
					dispatch(alertActions.setAlert({
						type: "danger",
						icon:  "icon-circle-warning-empty",
						text: t('events.create.warnings.nameRequired'),
						close: true,
					}));
					errorFound = true;
				}
			} else if (error.data?.organizerEmail) {
				if (error.data?.organizerEmail === "organizerRequired") {
					dispatch(alertActions.setAlert({
						type: "danger",
						icon:  "icon-circle-warning-empty",
						text: t('events.create.warnings.organizerRequired'),
						close: true,
					}));
					errorFound = true;
				} else if (error.data?.organizerEmail === "organizerInvalid") {
					dispatch(alertActions.setAlert({
						type: "danger",
						icon:  "icon-circle-warning-empty",
						text: t('events.create.warnings.organizerInvalid'),
						close: true,
					}));
					errorFound = true;
				}
			} else if (error.data?.startDate) {
				if (error.data?.startDate === "startEndInconsistent") {
					dispatch(alertActions.setAlert({
						type: "danger",
						icon:  "icon-circle-warning-empty",
						text: t('events.create.warnings.date'),
						close: true,
					}));
					errorFound = true;
				}
			}
			if (!errorFound){
				dispatch(alertActions.setAlert({
					type: "danger",
					icon:  "icon-circle-warning-empty",
					text: t('events.create.warnings.add'),
					close: true,
				}));
			}
		}
		finally {
			dispatch(appActions.setLoading(false));
		}
	};

	useEffect(() => {
		if(id) {
			getEvent();
		}
	}, [id]);

	useEffect(() => {	
		const isOpen = location.pathname.includes('eventOpen');
		if(isOpen) {
			setIsOpening(isOpen);
		}
	}, [location]);

	useEffect(() => {
		if(event) {
			setStartDate(new Date(event.startDate));
			setEndDate(new Date(event.endDate));
			setStyle(event.thumbnailUrl);
			setValue('organizerEmail', event.organizer.email);
			setValue('name', event.name);
			setValue('announced', event.announced);
			setValue('openForEnrollment', event.openForEnrollment);
			validDescription(JSON.parse(event.description));
			validLogo(event.thumbnailUrl);
		}
	}, [event]);

	useEffect(() => {
		valdiateDate();
	}, [endDate, startDate]);

	useEffect(() => {
		if(!id && userEmail) {
			setValue('organizerEmail', userEmail);
		}
	}, [userEmail]);

	useEffect(() => {
		const admin = CheckSecurityGroupPermission("canCreateEvents", securityGroup);

		if(admin) {
			setHaveAccess(admin);
		} else if (id && !admin && event && event.organizer?.email) {
			setHaveAccess(userEmail === event.organizer.email);
		} else {
			setHaveAccess(false);
		}
	}, [securityGroup, event, id, isLoggedIn]);

	if (!haveAcess) return <Error403 />;

	return (
		<div>
			<Container>
				<Alert />
				<form className={ styles["form"] } onSubmit={handleSubmit(onSubmit)}>
					{!isOpening && <p className={ styles["form__title"] }>{id ? t('events.create.titleEdit') : t('events.create.titleCreate')}</p>}
					{isOpening && <p className={ styles["form__title"] }>{t('events.create.buttons.open')}</p>}
					<p className={ styles["form__desc"] }>{isOpening ? 
						t('events.create.descriptionCreate')
						: t('events.create.descriptionEdit')}
					</p>
					<FormInput label={t('events.create.form.nameLabel')} register={register} name='name' isRequired
						errorMessage={errors.name && errors.name.message} 			
						validateData={{
							required: {
								value: true,
								message: t('events.create.form.required'),
							},
						}}
					/>
					<FormInput label={t('events.create.form.organizerLabel')} register={register} name='organizerEmail' isRequired
						errorMessage={errors.organizerEmail && errors.organizerEmail.message} 			
						validateData={{
							required: {
								value: true,
								message: t('events.create.form.required'),
							},
							pattern: {
								value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
								message: t('events.create.form.emailWarning'),
							},
						}}
					/>
					<Divider />
					<DatePickerComponent startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}
						error={errors.endDate}
					/>
					<Divider />
					<div>
						<p className={styles["form__subtitle"]}>{t('events.create.form.descriptionLabel')} {isOpening && '*'}</p>
						<Editor
							editorState={descriptionState}
							toolbarClassName={ styles["form__toolbar"] }
							wrapperClassName='demo-editor'
							editorClassName={ styles["form__wysiwyg"] }
							onEditorStateChange={onDescriptionStateChange}
							toolbar={{
								options: ['list', 'textAlign', 'link'],
								inline: {
									options: ['bold', 'italic'],
								},
							}}
						/>
						<p className={styles["form__error"]}>{isSubmitted && errors && errors.description && errors.description.message}</p>
					</div>
					<Divider />
					<div>
						<p className={styles["form__subtitle"]}>{t('events.create.form.openingLabel')}</p>
						<p className={styles["form__desc"]}>{t('events.create.form.openingDescription')}</p>
						<Editor
							editorState={openingState}
							toolbarClassName={ styles["form__toolbar"] }
							wrapperClassName='demo-editor'
							editorClassName={ styles["form__wysiwyg"] }
							onEditorStateChange={onOpeningStateChange}
							toolbar={{
								options: ['list', 'textAlign', 'link'],
								inline: {
									options: ['bold', 'italic'],
								},
							}}
						/>
					</div>
					<Divider />
					<div className={styles["form__logo-container"]}>
						<div>
							<p className={styles["form__subtitle"]}>{t('events.create.form.logoLabel')} {isOpening && '*'}</p>
							<p className={styles["form__desc"]}>{t('events.create.form.logoFormat')}</p>
							<input type='file' id='file' className={styles["form__custom-file-input"]} name='file'
								{...register("file")}
								accept='image/png, image/jpeg, image/jpg'
								onChange={onChangeFile}
							/>
							<div className={styles["form__desc"]}>{fileName}</div>
							<p className={styles["form__error"]}>{errors && errors.file && errors.file.message}</p>
						</div>
						<div className={styles["form__logo-img"]}>
							{style && <img src={style} alt='event create'/>}
						</div>
					</div>
					<Divider />
					<div>
						<p>{t('events.create.form.requiredFields')}</p>
					</div>
					<div className={styles["form__buttons-container"]}>
						<LinkButton href={id ? reverse(`${url.event}`, { id: id }) : url.event} variant='secondary'>{t('events.create.buttons.cancel')}</LinkButton>
						<Button type='submit'>
							{t('events.create.buttons.save')}
						</Button>
					</div>
				</form>
			</Container>
		</div>

	);
};

EventCreateEdit.propTypes = {};

export default EventCreateEdit;