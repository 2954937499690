import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";
import {useWindowSize} from "@/utils/windowSize";
import { alertActions } from "@/store/actions/alert.actions";
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { reverse } from 'named-urls';

import api from "@/services/api";
import url from "@/router/urls";

import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import SearchTranscriptions from "@Elements/Search/SearchTranscriptions";
import TileEvent from '@Components/Events/Tile/TileEvent';
import TilesEventContainer from '@Components/Events/Tile/TilesEventContainer';
import TilesEventRowContainer from '@Components/Events/Tile/TilesEventRowContainer';
import TileEventRow from '@Components/Events/Tile/TileEventRow';
import Pagination from "@Components/Pagination/Pagination";
import Button from "@Elements/Button/Button";
import Alert from "@Elements/Alert/Alert";
import LinkButton from "@Elements/Button/LinkButton";

import styles from "@Pages/Events/Events.module.sass";

const Events = () => {
	const [ searchString, setSearchString ] = useState("");
	const [ activeEventsData, setActiveEventsData ] = useState({});
	const [ completedEventsData, setCompletedEventsData] = useState({});
	const [ incomingEventsData, setIncomingEventsData] = useState({});
	const [unannouncedEvents, setUnannouncedEvents] = useState({});
	const [ currentPageActive, setCurrentPageActive ] = useState(1);
	const [ currentPageIncoming, setCurrentPageIncoming ] = useState(1);
	const [currentPageCompleted, setCurrentPageCompleted] = useState(1);
	const [currentPageUnannounced, setCurrentPageUnannounced] = useState(1);
	const [ isMyEvents, setIsMyEvents ] = useState(null);
	const [ selectMyEvents, setSelectMyEvents ] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);
	
	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useDispatch();

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label: t('events.events') , id: 2 },
	];

	const optionsMyEvents = [
		{value: "ORGANIZED_OR_ATTENDED_BY_ME", label: t('events.selectFilters.all')},
		{value: "ORGANIZED_BY_ME", label: t('events.selectFilters.creator')}, 
		{value: "ATTENDEND_BY_ME", label: t('events.selectFilters.attend')}, 
	];

	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);
	const userEmail = useSelector(state => state.auth.credentials.email);
	const securityGroup = useSelector((state) => state.auth.credentials.securityGroup);
	const isMobile = useWindowSize().width < 769;

	const getActiveEvents = async () => { 
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events?category=ACTIVE&page=${currentPageActive}&perpage=5${selectMyEvents ? `&onlyMine=${selectMyEvents}` : ''}`);
			if (res.status === 200) {
				setActiveEventsData(res.data);
				dispatch(appActions.setLoading(false));
			}

		} catch (
			err
		) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.getActiveEvents'),
				close: true,
			}));
		}
	};

	const getIncomingEvents = async () => { 
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events?category=ANNOUNCED&page=${currentPageIncoming}&perpage=5${selectMyEvents ? `&onlyMine=${selectMyEvents}` : ''}`);

			if (res.status === 200) {
				setIncomingEventsData(res.data);
				dispatch(appActions.setLoading(false));
			}

		} catch (
			err
		) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.getIncomingEvents'),
				close: true,
			}));
		}
	};

	const getUnannouncedEvents = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events?category=UNANNOUNCED&page=${currentPageUnannounced}&perpage=5${selectMyEvents ? `&onlyMine=${selectMyEvents}` : ''}`);
			if (res.status === 200) {
				setUnannouncedEvents(res.data);
				dispatch(appActions.setLoading(false));
			}

		} catch (error) {
			
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.getUnannouncedEvents'),
				close: true,
			}));
		}
	};

	const getCompletedEvents = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events?category=COMPLETED&page=${currentPageCompleted}&perpage=4${selectMyEvents ? `&onlyMine=${selectMyEvents}` : ''}`);
			if (res.status === 200) {
				setCompletedEventsData(res.data);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.getCompletedEvents'),
				close: true,
			}));
			
		}
	};

	const checkUserAttend = (event) => {
		if(isLoggedIn) {
			return  event.attendees.some(attendee => attendee.user.email === userEmail);
		}
	};

	const changeMYEventsFilter = (e) =>{
		setSelectMyEvents(e.target.value);
	};

	useEffect(() => {
		isMyEvents != null && getActiveEvents();
	}, [currentPageActive, selectMyEvents, isMyEvents]);

	useEffect(() => {
		isMyEvents != null && getIncomingEvents();
	}, [currentPageIncoming, selectMyEvents, isMyEvents]);
	
	useEffect(() => {
		isMyEvents != null && getCompletedEvents();
	}, [currentPageCompleted, selectMyEvents, isMyEvents]);

	useEffect(() => {
		if(isAdmin && isMyEvents != null) {
			getUnannouncedEvents();
		}
	}, [currentPageUnannounced, isAdmin, selectMyEvents, isMyEvents]);

	useEffect(() => {
		const admin = CheckSecurityGroupPermission("canCreateEvents",securityGroup);
		setIsAdmin(admin);
	}, [securityGroup]);
	
	useEffect(() => {
		const myEvents = location.pathname.includes(url.myEvents);
		
		if(myEvents) {
			setSelectMyEvents("ORGANIZED_OR_ATTENDED_BY_ME");
		} else {
			setSelectMyEvents('');
		}
		setIsMyEvents(myEvents);

	}, [location.pathname]);
	
	return (
		<main className={ styles["events"] }>
			<SearchTranscriptions
				setSearchString={ setSearchString }
				searchString={ searchString }
			/>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
			</Container>
			<Container>
				<Alert />
				<div className={ styles["events__header"] }>
					<div className={ styles["events__header__inner"] }>
						<h2 className={ styles["events__title"] }>{t('events.events')}</h2> 						
						{(!isMobile && isAdmin) && 
						<LinkButton href={reverse(url.eventCreate)} variant='primary' className={ styles["event__button--attend"] }>
							{t('events.buttons.create')}
						</LinkButton>}
					</div>
					
					<p className={ styles["events__description"] }>{t('events.description')}</p>
					{(isMobile && isAdmin) && 
					<Button variant='primary' className={ styles["event__button--attend"] }>
						{t('events.buttons.create')}
					</Button>}
				</div>
			</Container>
			<Container>
				<>
					<div className={ styles["events__select-container"] }>				
						{isAdmin ? <div className={ styles[`${unannouncedEvents.hits > 4 ? 'events__tiles__title--pagination' : 'events__tiles__title'}`] }>{t('events.tile.unannounced')} ({unannouncedEvents?.hits ? unannouncedEvents.hits : 0})</div> :<div />}

						{isMyEvents && 
						<div>
							<select className={ styles["events__select"] } onChange={changeMYEventsFilter} value={selectMyEvents}>
								{optionsMyEvents.map(option => (<option key={option.label} value={option.value}>{option.label}</option>))}
							</select>
						</div>}
					</div>
					{unannouncedEvents && unannouncedEvents.hits > 0 &&
					<>			
						<Pagination
							currentPage={unannouncedEvents.page}
							pagesNum={unannouncedEvents.pages}
							onGoToPage={setCurrentPageUnannounced}
							hideIfOnePage
						>
							{
								unannouncedEvents?.events?.length > 0 && 
								<>
									<TilesEventContainer>
										{unannouncedEvents.events.map(event => <TileEvent key={event.id} event={event} attend={checkUserAttend(event)} />)}
									</TilesEventContainer>
								</>
							}
						</Pagination>
					</>}
					<div>
						<div className={ styles[`${activeEventsData.hits > 4 ? 'events__tiles__title--pagination' : 'events__tiles__title'}`] }>{t('events.tile.active')} ({activeEventsData?.hits ? activeEventsData.hits : 0})</div>
					
						{activeEventsData && activeEventsData.hits > 0 &&
						<>
						
							<Pagination
								currentPage={activeEventsData.page}
								pagesNum={activeEventsData.pages}
								onGoToPage={setCurrentPageActive}
								hideIfOnePage
							>
								{
									activeEventsData?.events?.length > 0 && 
									<>
										<TilesEventContainer>
											{activeEventsData.events.map(event => <TileEvent key={event.id} event={event} attend={checkUserAttend(event)} />)}
										</TilesEventContainer>
									</>
								}
							</Pagination>
						</>}
					</div>
					<div>
						<div className={ styles[`${incomingEventsData.hits > 4 ? 'events__tiles__title--pagination' : 'events__tiles__title'}`] }>{t('events.tile.announced')} ({incomingEventsData?.hits ? incomingEventsData.hits : 0})</div>
						{incomingEventsData && incomingEventsData.hits > 0 &&
						<>

							<Pagination
								currentPage={incomingEventsData.page}
								pagesNum={incomingEventsData.pages}
								onGoToPage={setCurrentPageIncoming}
								hideIfOnePage
							>
								{
									incomingEventsData?.events?.length > 0 && 
									<>
										<TilesEventContainer>
											{incomingEventsData.events.map(event => <TileEvent type='tile-event' key={event.id} event={event} attend={checkUserAttend(event)} />)}
										</TilesEventContainer>
									</>
								}
							</Pagination>
						</>}
					</div>
					<div>
						<div className={ styles[`${completedEventsData.hits > 4 ? 'events__tiles__title--pagination' : 'events__tiles__title'}`] }>{t('events.tile.past')} ({completedEventsData?.hits ? completedEventsData.hits : 0})</div>	
						{completedEventsData && completedEventsData.hits > 0 && 
						<>
								
							<Pagination
								currentPage={completedEventsData.page}
								pagesNum={completedEventsData.pages}
								onGoToPage={setCurrentPageCompleted}
							>
								{
									completedEventsData?.events?.length > 0 && 
									<>
										<TilesEventRowContainer>
											{completedEventsData.events.map(event => <TileEventRow type='tile-event-row' key={event.id} event={event}/>)}
										</TilesEventRowContainer>
									</>
								}
							</Pagination>
						</>}
					</div>
				</>
			</Container>
		</main>
	);
};

Events.propTypes = {};

export default Events;